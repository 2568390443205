import React, { useState } from 'react';
import { Link, navigate } from 'gatsby';

import Button from '../Button';
import FormInputField from '../FormInputField/FormInputField';
import CurrencyFormatter from '../CurrencyFormatter';
import { loadStripe } from '@stripe/stripe-js';

import * as styles from './OrderSummary.module.css';

const OrderSummary = (props) => {
  const [coupon, setCoupon] = useState('');
  const [giftCard, setGiftCard] = useState('');

  let stripePromise;
  const getStripe = () => {
    if (!stripePromise) {
      stripePromise = loadStripe(process.env.GATSBY_STRIPE_PUBLISHABLE_KEY);
    }
    return stripePromise;
  };

  const redirectToCheckout = async (event) => {
    event.preventDefault();

    const stripe = await getStripe();

    const { error } = await stripe.redirectToCheckout({
      mode: 'payment',
      lineItems: props.cart.map((item) => {
        return {
          price: item.stripe_id,
          quantity: item.quantity,
        };
      }),
      successUrl: `${window.location.origin}/orderConfirm/`,
      cancelUrl: `${window.location.origin}/`,
    });

    if (error) {
      console.warn('Error:', error);
    }
  };

  return (
    <div className={styles.root}>
      <div className={styles.orderSummary}>
        <span className={styles.title}>order summary</span>
        <div className={styles.calculationContainer}>
          <div className={styles.labelContainer}>
            <span>Subtotal</span>
            <span>
              <CurrencyFormatter amount={props.subTotal} appendZero />
            </span>
          </div>
          <div className={styles.labelContainer}>
            <span>Shipping</span>
            <span>---</span>
          </div>
          <div className={styles.labelContainer}>
            <span>Tax</span>
            <span>
              <CurrencyFormatter amount={0} appendZero />
            </span>
          </div>
        </div>
        <div className={styles.couponContainer}>
          <span>Coupon Code</span>
          <FormInputField
            value={coupon}
            handleChange={(_, coupon) => setCoupon(coupon)}
            id={'couponInput'}
            icon={'arrow'}
          />
          <span>Gift Card</span>
          <FormInputField
            value={giftCard}
            handleChange={(_, giftCard) => setGiftCard(giftCard)}
            id={'couponInput'}
            icon={'arrow'}
          />
        </div>
        <div className={styles.totalContainer}>
          <span>Total: </span>
          <span>
            <CurrencyFormatter amount={props.subTotal} appendZero />
          </span>
        </div>
      </div>
      <div className={styles.actionContainer}>
        <Button onClick={redirectToCheckout} fullWidth level={'primary'}>
          checkout
        </Button>
        <div className={styles.linkContainer}>
          <Link to={'/shop'}>CONTINUE SHOPPING</Link>
        </div>
      </div>
    </div>
  );
};

export default OrderSummary;
