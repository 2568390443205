import React, { useState } from 'react';

import AdjustItem from '../AdjustItem';
import CurrencyFormatter from '../CurrencyFormatter';
import Drawer from '../Drawer';
import RemoveItem from '../RemoveItem';
import QuickView from '../QuickView';

import * as styles from './CartItem.module.css';
import { navigate } from 'gatsby';
import { useContext } from 'react';
import { AppContext } from '../Context/Provider';

const CartItem = (props) => {
  const [showQuickView, setShowQuickView] = useState(false);
  const { image, alt, color, name, size, price, quantity, id } = props;

  const { incrementQuantity, decrementQuantity, removeItemFromCart } =
    useContext(AppContext);

  return (
    <div className={styles.root}>
      <div
        className={styles.imageContainer}
        role={'presentation'}
        onClick={() => navigate('/product/sample')}
      >
        <img src={image} alt={alt}></img>
      </div>
      <div className={styles.itemContainer}>
        <span className={styles.name}>{name}</span>
        <div className={styles.metaContainer}>
          <span>Color: {color}</span>
          <span>Size: {size}</span>
        </div>
        <div
          className={styles.editContainer}
          role={'presentation'}
          onClick={() => setShowQuickView(true)}
        >
          <span>Edit</span>
        </div>
      </div>
      <div className={styles.adjustItemContainer}>
        <AdjustItem
          quantity={quantity}
          incrementQuantity={() => incrementQuantity(id)}
          decrementQuantity={() => decrementQuantity(id)}
        />
      </div>
      <div className={styles.priceContainer}>
        <CurrencyFormatter amount={price * quantity} appendZero />
      </div>
      <div className={styles.removeContainer}>
        <RemoveItem removeItem={() => removeItemFromCart(id)} />
      </div>
      <Drawer
        product={showQuickView && props}
        close={() => setShowQuickView(false)}
      >
        <QuickView close={() => setShowQuickView(false)} activeProd={props} />
      </Drawer>
    </div>
  );
};

export default CartItem;
